import React from "react"
import { MdKeyboardDoubleArrowUp } from "react-icons/md"
import { FaArrowRight } from "react-icons/fa"



export const BackToTop = ({ padding = "50px", ...props }) => {
  return (
    <div style={{
      position: "sticky",
      bottom: 0,
      padding: padding,
      width: "100%",
      zIndex: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      backgroundColor: "transparent",
    }}>
      <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth", })} className="GreyHover">
        Top&nbsp;<MdKeyboardDoubleArrowUp />
      </button>
    </div >
  )
}


export const NewsBar = ({ newsTitle, link, fontSize = "16px" }) => {
  return (
    <div className="GreyHover" style={{ border: "solid 1px grey", borderRadius: "20px", padding: "5px 10px" }}>
      <p style={{ fontSize }}>
        📣 {newsTitle} {link &&
          <a href={link} aria-label="Explore" target="_blank" rel="noreferrer">
            <FaArrowRight />
          </a>
        }
      </p>
    </div>
  )
}


export const Loading = props => {
  const { statusMsg, style } = props

  return (
    <div className="container Loading" style={{ ...style }}>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      <br />
      {statusMsg && <p >{statusMsg}</p>}
    </div >
  )
}