import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Home } from "./components/Home"
// import { SpeedInsights } from "@vercel/speed-insights/react"


export const App = ({ tabletMode, mobileMode }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home tabletMode={tabletMode} mobileMode={mobileMode} />} />
      </Routes>
    </BrowserRouter >
  )
}
