import { useState } from "react"
import { handleGtag } from "./gtag"
import { FaLinkedin, FaGithub } from "react-icons/fa"


export const Header = () => {
  return (
    <nav className="navbar container" style={{ display: "flex", padding: "0 20px" }}>
      <div className="navbar-start" style={{ display: "flex" }}>
        <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleGtag(e, "header", "icon")}>
          <img
            src="https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
            alt="Home" height={40} width={96} style={{ minHeight: "40px", maxWidth: "96px" }}
          />
        </a>

        <a className="navbar-item" aria-label="navbar item" href="/#how-it-works" onClick={e => handleGtag(e, "header")}>
          How It Works
        </a>

        <div className="navbar-item has-dropdown is-hoverable">
          <a className="navbar-link" href="/#use-cases" onClick={e => handleGtag(e, "header")}>
            Use Cases
          </a>

          <div className="navbar-dropdown">
            <a className="navbar-item" aria-label="navbar item" href="/#use-cases" onClick={e => handleGtag(e, "header")}>
              Upsell, Cross-sell
            </a>
            <a className="navbar-item" aria-label="navbar item" href="/#use-cases" onClick={e => handleGtag(e, "header")}>
              Lead Generation
            </a>
            <a className="navbar-item" aria-label="navbar item" href="/#use-cases" onClick={e => handleGtag(e, "header")}>
              Engagement
            </a>
          </div>
        </div>

        <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="https://versi0n.io" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
          Playground
        </a>
      </div>

      <div className="navbar-end" style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
        <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://pypi.org/project/versionhq/" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
          Developer
        </a>
        <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://github.com/versionHQ" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", "github")}>
          <FaGithub style={{ height: "100px" }} />
        </a>
        <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://www.linkedin.com/company/version-hq/?viewAsMember=true" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", "linkedin")}>
          <FaLinkedin style={{ height: "100px" }} />
        </a>
        <button className="button is-dark" aria-label="navbar item" style={{ display: "flex", alignItems: "center", height: "80%", marginLeft: "10px" }} onClick={e => { handleGtag(e, "header"); window.location.href = "/#custom-demo" }}>
          Schedule Demo
        </button>
      </div>
    </nav >
  )
}


export const MobileHeader = () => {
  const [isOpenBurger, setIsOpenBurger] = useState(false)

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <div className="navbar-start" style={{ display: "flex" }}>
          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="/">
            <img
              src="https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
              alt="Home" height={40} width={96} style={{ minHeight: "40px", maxWidth: "96px" }}
            />
          </a>
        </div>

        <div className={isOpenBurger ? "Burger navbar-burger is-active" : "Burger navbar-burger"} onClick={() => setIsOpenBurger(!isOpenBurger)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      {isOpenBurger &&
        <div className="navbar-dropdown" id="burger-menu">
          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="/#how-it-works" onClick={e => handleGtag(e, "header")}>
            How It Works
          </a>
          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", flexDirection: "column" }} href="/#use-cases" onClick={e => handleGtag(e, "header")}>
            Use Cases
            <ul style={{ marginLeft: "30px" }}>
              <li>Upsell, Cross-sell</li>
              <li>Churn Prevention</li>
              <li>Lead Generation</li>
            </ul>
          </a>

          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="https://versi0n.io" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
            Playground
          </a>
          <a className="navbar-item" aria-label="navbar item" style={{ display: "flex", alignItems: "center" }} href="/#custom-demo" onClick={e => handleGtag(e, "header")}>
            Schedule Demo
          </a>
          <a className="navbar-item" aria-label="navbar item" href="#founder" onClick={e => handleGtag(e, "header")}>
            About
          </a>
          <hr style={{ margin: 0 }} />
          <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://pypi.org/project/versionhq/" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header")}>
            PyPI
          </a>
          <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://github.com/versionHQ/multi-agent-system" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", "github")}>
            Contribute&nbsp;<FaGithub style={{ height: "20px" }} />
          </a>
        </div>
      }
    </nav>
  )
}