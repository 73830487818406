import React from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App"
import "./main.scss"


const root = createRoot(document.getElementById("root"))
var mobileMode = Boolean(window.innerWidth <= 430)
var tabletMode = Boolean(window.innerWidth <= 800)


root.render(<App tabletMode={tabletMode} mobileMode={mobileMode} />)
