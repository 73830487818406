import { useState, Suspense } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { InlineWidget } from "react-calendly"
import { FaLinkedin } from "react-icons/fa"
import { handleGtag } from "./gtag"
import { Header, MobileHeader } from "./Header"
import { Footer, MobileFooter } from "./Footer"
import { BackToTop, NewsBar, Loading } from "./Elements"
var domain = window.location.origin
var carouselCompanies = [
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo-155/v1721811096/pj_m_test/i2nbnlnxdcszpsqdohca.png",
    alt: "walt disney", height: "30", width: "200"
  },
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo/v1721811097/pj_m_test/drcmvcf68glnyn0b39m0.png",
    alt: "meta", height: "70", width: "125"
  },
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo-155/v1721811096/pj_m_test/s3e3q0xoayabroccd8di.png",
    alt: "inmobi", height: "20", width: "136"
  },
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo-155/v1721811095/pj_m_test/n50q9db15ulzzcozdi6w.png",
    alt: "mineski", height: "58", width: "150"
  },
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo-155/v1721811096/pj_m_test/dgsqh1lqnwghohaprjch.png",
    alt: "insead", height: "71", width: "155"
  },
]
var howItWorks = [
  {
    h4: "Learn.",
    h5: "Learn about the customer",
    exp: "Agents will run clustering analysis through seamless integration to the data pipeline and identify communication strategies suitable for each individual.",
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1728299986/pj_m_home/ilx7hcrrj7rzedkbx7lq.png",
  },
  {
    h4: "Communicate.",
    h5: "Desgin messaging workflow",
    exp: "Based on the learning, agents will start to design messaging workflows and deploy them on the destination services.",
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/t_width 300 x height auto/v1728302420/pj_m_home/xy58a7imyquuvkgukqxt.png"
  },
  {
    h4: "Convert with AI",
    h5: "Turn leads into loyal customers",
    exp: "Agents will continiously assess the actual performance vs prediction and keep iterating the workflows.",
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1728304264/pj_m_home/eesrfobwa33hbauv6po1.png"
  }
]
var useCases = [
  {
    backgroundImg: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728401906/pj_m_home/tmn4kidjiik9wkrvzxjl.png",
    backgroundImgSelected: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728383839/pj_m_home/cbinkonfxjugq8k9sz5t.png",
    title: "Upsell / Cross-sell",
    bottomPosition: "120px",
  },
  {
    backgroundImg: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728401661/pj_m_home/mbotk1uyvkyqt2hl3agd.png",
    backgroundImgSelected: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728383839/pj_m_home/bkju706ouvxwc6qkevrd.png",
    title: "Churn Prevention",
    bottomPosition: "110px"

  },
  {
    backgroundImg: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728401907/pj_m_home/z6cpsncl4pmwghrwknek.png",
    backgroundImgSelected: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728383838/pj_m_home/foowahttxyjnus8zlmvp.png",
    title: "Lead Generation",
    bottomPosition: "130px"
  }
]
var faqList = [
  {
    q: "Which lead gen/engagment channels do you support?",
    a: "We support retention channels (Emails, push notification, SMS, WhatsApp) and acquisition channels (landing page, display ads). "
  },
  {
    q: "How often do I need to iterate messaging?",
    a: "It depends on your product type, ideal purchase frequency, and cohort type. We'll learn the best timing and automatically update the message accordingly."
  },
  {
    q: "How do you predict content performance?",
    a: "We consider historical performance, matching brand tone and audience, and other factual correctness.",
  },
  {
    q: "Which CRM platforms and data formats do you support to integrate?",
    a: "We support Hubspot, Salesforce, and CSV/excel files.",
  },
  {
    q: "Is my training data secure?",
    a: "Your training data is securely stored on our server and accessible exclusively on your private pages. All data transfers are encrypted to protect your privacy."
  },
  {
    q: "Who owns the training data?",
    a: "You retain the ownership when you use the dataset to train the model. Data from external providers is owned by us or those providers.",
  },
  {
    q: "Do you use personal data?",
    a: "No, we don't. For the personalization, we optimize messaging with field tags such as { first_name } without the actual PII values, and all the data is contextualized further with categorical data such as cohort type."
  },
]


export const Home = ({ tabletMode, mobileMode }) => {
  const [activeMenuId, setActiveMenuId] = useState(0)

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href="https://home.versi0n.io/" />
      </Helmet>

      {tabletMode ? <MobileHeader /> : <Header />}

      <hr style={{ margin: 0 }} />

      <div className="content">
        <div className="container" style={{ maxHeight: "800px", maxWidth: tabletMode ? "100%" : "1200px", padding: tabletMode ? "50px 20px" : "100px 50px 50px", display: "flex", flexDirection: "column", alignItems: "center" }}>

          <NewsBar
            newsTitle={"Currently on the Accelerator Program with AWS"}
            link={"https://www.linkedin.com/in/k-i-i/"}
            fontSize={tabletMode ? "12px" : "16px"}
          />

          <h1 style={{ lineHeight: tabletMode ? 1.4 : 1.5, fontWeight: 700, padding: tabletMode ? "20px 0" : "0 0 10px 0" }} itemProp="description">
            Acquire <span style={{ background: "-webkit-linear-gradient(left, #00d1b2, #169d87)", backgroundClip: "text", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>loyal customers</span> with
            <br />
            hyper-personalized messaging
          </h1>

          <h2 style={{ lineHeight: 1.1, padding: "0 0 30px 0" }}>
            Learn. Communicate. Convert with AI.
          </h2>

          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <button
              className="button is-primary"
              style={{ width: tabletMode ? "150px" : "250px", fontSize: tabletMode ? undefined : "21px" }}
              onClick={e => { handleGtag(e, "home"); window.open(`https://versi0n.io`, "_blank") }}
            >
              Try on Playground
            </button>
            <button
              className="button is-dark"
              style={{ marginLeft: tabletMode ? "5px" : "30px", fontSize: tabletMode ? undefined : "21px", width: tabletMode ? "220px" : "300px" }}
              onClick={e => { handleGtag(e, "home"); window.location.href = `${domain}/#custom-demo` }}
            >
              Schedule a demo
            </button>
          </div>
        </div>

        <div className="container" style={{ minHeight: tabletMode ? undefined : "280px", width: "100%", minWidth: "100%", padding: tabletMode ? "20px" : "60px 0 40px 0", display: "flex", flexDirection: "column", alignItems: "center", }}>
          <h3 style={{ fontWeight: 550 }}>Built by a team that scaled growth at:</h3>
          <div className="container" style={{ display: "flex", flexWrap: tabletMode ? "wrap" : undefined, width: "100%", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
            {carouselCompanies.map((item, i) => {
              return (
                <img
                  key={i} src={item.logo} alt={item.alt} height={tabletMode ? Number(item.height) / 2 : item.height} width={tabletMode ? Number(item.width) / 2 : item.width} style={{
                    margin: tabletMode ? "5px" : "30px"
                  }}
                />
              )
            })}
          </div>
        </div>

        <div className="container" style={{ padding: mobileMode ? "50px 10px" : tabletMode ? "50px" : "10px 10px 80px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minWidth: "100%" }}>
          {/* <div itemscope itemtype="https://schema.org/VideoObject"> */}
          <video
            className="card"
            width={mobileMode ? 360 : 840}
            style={{ borderRadius: "3px", border: "1px solid black", backgroundColor: "#191b1b" }}
            controls autoplay muted itemscope itemtype="https://schema.org/VideoObject">
            <source
              src="https://res.cloudinary.com/dfeirxlea/video/upload/v1737732977/pj_m_home/pnsyh5mfvmilwgt0eusa.mov"
              type="video/mp4"
            />
          </video>
          <p style={{ display: "none" }} itemprop="accountablePerson">Kuriko Iwai</p>
          <p style={{ display: "none" }} itemprop="copyrightHolder">Version IO Sdn. Bhd.</p>
          {/* </div> */}

          <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <button
              className="button is-primary"
              style={{ width: tabletMode ? "150px" : "250px", fontSize: tabletMode ? undefined : "21px" }}
              onClick={e => { handleGtag(e, "home"); window.open(`https://versi0n.io`, "_blank") }}
            >
              Playground
            </button>
            <button
              className="button is-dark"
              style={{ marginLeft: tabletMode ? "5px" : "30px", fontSize: tabletMode ? undefined : "21px", width: tabletMode ? "220px" : "300px" }}
              onClick={e => { handleGtag(e, "home"); window.location.href = `${domain}/#custom-demo` }}
            >
              Schedule a demo
            </button>
          </div>
        </div>

        <hr />

        <div className="container" id="how-it-works" style={{ padding: tabletMode ? "60px 20px 30px" : "120px 80px", display: "flex", flexDirection: "column", alignItems: "center", minWidth: "100%" }}>
          <h2>Messaging workflows at an individual level</h2>
          <h3>Powered by model-agnostic AI agents</h3>

          <div style={{ width: tabletMode ? "100%" : "1000px", marginTop: tabletMode ? "5px" : "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

            {howItWorks.map((item, i) => {
              const { h4, h5, exp, img } = item
              return (
                <div key={i} className="card" style={{ width: tabletMode ? "100%" : "1000px", margin: "30px 0", padding: "30px", borderRadius: "10px", minHeight: "400px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ borderRadius: "30px", backgroundColor: "#00d1b2", color: "#fafafa", height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", margin: "0 10px 0.8rem 0" }}>
                      {i + 1}
                    </div>
                    <h4>{h4}</h4>
                  </div>
                  <hr style={{ backgroundColor: "#00d1b2", margin: "0 0 30px 0", height: "0.5px" }} />
                  <div className="container" style={{ display: "flex", flexDirection: tabletMode ? "column" : "row", justifyContent: "center", }}>
                    <div className="container" style={{ marginRight: tabletMode ? 0 : "50px" }}>
                      <h5>{h5}</h5>
                      <p>{exp}</p>
                    </div>
                    <div className="container" style={{ display: "flex", justifyContent: "center", marginTop: tabletMode ? "10px" : 0 }}>
                      <img src={img} alt={exp} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <hr />

        <div className="container" id="use-cases" style={{ padding: tabletMode ? "60px 20px 30px" : "120px 80px", display: "flex", flexDirection: "column", alignItems: "center", minWidth: "100%" }}>
          <h2>Leverage multiple touchpoints</h2>
          <h3>Send consistent, competitive messages throughout the customer journey.</h3>

          {tabletMode
            ?
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minWidth: "100%" }}>
              <button style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "140px", marginTop: "50px" }}>
                <img src={useCases[0].backgroundImg} alt="background" width={200} height={"auto"} />
                <h4 style={{ position: "relative", bottom: "90px", color: "#191b1b" }}>
                  {useCases[0].title}
                </h4>
              </button>
              <div className="card" style={{ minWidth: "350px", maxWidth: mobileMode ? "400px" : "800px", padding: "20px" }}>
                <p>
                  <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                  An average consumer needs to purchase <span style={{ fontWeight: 700 }}>at least 3 times</span> to feel loyal to the service.
                </p>
                <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
                <div style={{ display: "flex", alignItems: "center", flexDirection: mobileMode ? "column" : "row" }}>
                  <img
                    src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728396141/pj_m_home/tufwfayq34jiofjxmypp.png"
                    alt="received push notification" width={mobileMode ? 520 : 350} height={"auto"}
                    style={{ margin: "10px" }}
                  />
                  <div>
                    <p style={{ fontWeight: 700 }}>170% Sales Increase</p>
                    <p>We predict individual customer needs and offer timely product recommendations.</p>
                  </div>
                </div>
              </div>

              <button style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "140px", marginTop: "50px" }}>
                <img src={useCases[1].backgroundImg} alt="background" width={200} height={"auto"} />
                <h4 style={{ position: "relative", bottom: "90px", color: "#191b1b" }}>
                  {useCases[1].title}
                </h4>
              </button>
              <div className="card" style={{ minWidth: "350px", maxWidth: mobileMode ? "400px" : "800px", padding: "20px" }}>
                <p>
                  <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                  <span style={{ fontWeight: 700 }}>93% of customers</span> will never return after a single disappointing experience.
                </p>
                <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
                <div style={{ display: "flex", alignItems: "center", flexDirection: mobileMode ? "column" : "row" }}>
                  <img
                    src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728397449/pj_m_home/hdzmhwgofjvlpgnfoqwv.png"
                    alt="Engaging user" width={mobileMode ? 520 : 350} height={"auto"}
                    style={{ margin: "10px" }}
                  />
                  <div>
                    <p style={{ fontWeight: 700 }}>1.5x Customer Lifetime Value</p>
                    <p>Predict the churn risk real-time and proactively reach out with personalized offers.</p>
                  </div>
                </div>
              </div>

              <button style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "140px", marginTop: "50px" }}>
                <img src={useCases[2].backgroundImg} alt="background" width={200} height={"auto"} />
                <h4 style={{ position: "relative", bottom: "110px", color: "#191b1b" }}>
                  {useCases[2].title}
                </h4>
              </button>
              <div className="card" style={{ minWidth: "350px", maxWidth: mobileMode ? "400px" : "800px", padding: "20px" }}>
                <p>
                  <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                  Acquring loyal users costs <span style={{ fontWeight: 700 }}>25 times</span> more than retaining them.
                </p>
                <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
                <div style={{ display: "flex", alignItems: "center", flexDirection: mobileMode ? "column" : "row" }}>
                  <img
                    src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728400881/pj_m_home/zhhwtfztyxtyysqbvkaf.png"
                    alt="Ad campaign" width={mobileMode ? 520 : 350} height={"auto"}
                    style={{ margin: "10px" }}
                  />
                  <div>
                    <p style={{ fontWeight: 700 }}>2x CTR Increase</p>
                    <p>Define loyal customers and develop targeted display ad campaigns.</p>
                  </div>
                </div>
              </div>
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minWidth: "100%" }}>
              <div className="container" style={{ display: "flex", width: "900px", justifyContent: "space-between", margin: "70px 0 40px" }}>
                {useCases.map((item, i) => {
                  const { title, backgroundImg, backgroundImgSelected, bottomPosition } = item
                  return (
                    <button key={i} style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "200px" }} onClick={() => setActiveMenuId(i)}>
                      <img src={activeMenuId === i ? backgroundImgSelected : backgroundImg} alt="background" width={240} height={"auto"} />
                      <h4 style={{ position: "relative", bottom: bottomPosition, color: "#191b1b" }}>
                        {title}
                      </h4>
                    </button>
                  )
                })}
              </div>

              {activeMenuId === 0 &&
                <div className="card" style={{ minWidth: "1000px", maxWidth: "1000px", minHeight: "500px", maxHeight: "500px", padding: "20px" }}>
                  <p>
                    <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                    An average consumer needs to purchase <span style={{ fontWeight: 700 }}>at least 3 times</span> to feel loyal to the service.
                  </p>
                  <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728396141/pj_m_home/tufwfayq34jiofjxmypp.png"
                      alt="received push notification" width={520} height={"auto"}
                      style={{ margin: "10px" }} />
                    <div>
                      <p style={{ fontWeight: 700, fontSize: "24px" }}>170% Sales Increase</p>
                      <p>We predict individual customer needs and offer timely product recommendations.</p>
                    </div>
                  </div>
                </div>
              }

              {activeMenuId === 1 &&
                <div className="card" style={{ minWidth: "1000px", maxWidth: "1000px", minHeight: "500px", maxHeight: "500px", padding: "20px" }}>
                  <p>
                    <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                    <span style={{ fontWeight: 700 }}>93% of customers</span> will never return after a single disappointing experience.
                  </p>
                  <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728397449/pj_m_home/hdzmhwgofjvlpgnfoqwv.png"
                      width={500} height={"auto"} style={{ margin: "10px" }} alt="Engaging user"
                    />
                    <div>
                      <p style={{ fontWeight: 700, fontSize: "24px" }}>1.5x Customer Lifetime Value</p>
                      <p>Predict the churn risk real-time and proactively reach out with personalized offers.</p>
                    </div>
                  </div>
                </div>
              }

              {activeMenuId === 2 &&
                <div className="card" style={{ minWidth: "1000px", maxWidth: "1000px", minHeight: "500px", maxHeight: "500px", padding: "20px" }}>
                  <p>
                    <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                    Acquring loyal users costs <span style={{ fontWeight: 700 }}>25 times</span> more than retaining them.
                  </p>
                  <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728400881/pj_m_home/zhhwtfztyxtyysqbvkaf.png"
                      width={500} height={"auto"} style={{ margin: "10px" }} alt="Ad campaign"
                    />
                    <div>
                      <p style={{ fontWeight: 700, fontSize: "24px" }}>2x CTR Increase</p>
                      <p>Define loyal customers and develop targeted display ad campaigns.</p>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>

        <div className="container" id="custom-demo" style={{ minHeight: tabletMode ? undefined : "500px", padding: tabletMode ? "60px 20px 30px" : "120px 80px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2>Schedule a demo</h2>
          <p>30 mins for a demo + Q&A</p>
          <Suspense fallback={<Loading />}>
            <InlineWidget
              url="https://calendly.com/kuriko-i/custom-demo?hide_event_type_details=1&hide_gdpr_banner=1"
              styles={{ marginTop: "30px", width: tabletMode ? "360px" : "500px", height: tabletMode ? "500px" : "600px" }}
            />
          </Suspense>
        </div>

        <hr />

        <div className="container" id="faq" style={{ minHeight: "700px", width: "100%", minWidth: "100%", padding: tabletMode ? "60px 20px 30px" : "120px 80px", display: "flex", flexDirection: "column", alignItems: "center", }}>
          <h2>FAQ</h2>
          <div style={{ width: tabletMode ? "100%" : "800px", marginTop: "20px" }}>
            {faqList.map((item, i) => {
              return (
                <div key={i} style={{ padding: "16px 0", borderBottom: "solid 0.3px grey" }}>
                  <p style={{ fontWeight: 700 }}>{item.q}</p>
                  <p>{item.a}</p>
                </div>
              )
            }
            )}
          </div>
        </div>

        <hr />

        <div className="container" id="founder" style={{ minHeight: "500px", width: "100%", minWidth: "100%", padding: mobileMode ? "30px 20px 60px" : tabletMode ? "60px 20px 30px" : "80px", display: "flex", flexDirection: "column", alignItems: "center" }} itemScope itemType="https://schema.org/Person">
          <h2>Founder</h2>
          <div className="container" style={{ maxHeight: "800px", maxWidth: tabletMode ? "100%" : "1200px", padding: tabletMode ? "10px" : "80px 50px", display: "flex", flexDirection: tabletMode ? "column" : "row", alignItems: "center" }}>
            <div style={{ marginRight: tabletMode ? 0 : "50px", minWidth: "300px" }}>
              <img
                src="https://res.cloudinary.com/dfeirxlea/image/upload/t_prof img 300x300/v1726493035/portfolio/v3kci4ocszbd1l1gh3cv.png"
                alt="Kuriko"
                width="300" height="300" style={{ boxShadow: "10px 10px #00d1b2", borderRadius: "10px" }}
                loading="lazy"
                itemProp="image"
              />
              <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                <a href="https://www.linkedin.com/in/k-i-i/" aria-label="Jump to LinkedIn page" onClick={e => handleGtag(e, "founder", "linkedin")} target="_blank" rel="noreferrer">
                  <FaLinkedin style={{ width: "30px", marginRight: "10px" }} />
                </a>
                <a href="https://kuriko.vercel.app" aria-label="Jump to my profile" onClick={e => handleGtag(e, "founder", "portfolio")} target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>
                  Portfolio
                </a>
                <span style={{ display: "none" }} itemProp="founder">Kuriko I.</span>
                <span style={{ display: "none" }} itemProp="founder">Kuriko IWAI</span>
              </div>
            </div>

            <div>
              <p>Hi, I am Kuriko!</p>
              <p>I'm founder of version. I've been in the tech world almost for a decade as a builder, entrepreneur, and product owner.</p>
              <p>
                From Big Companies to Startups - For years, I worked at amazing companies like Meta and Disney and even building several businesses from the ground up. But you know what I noticed? There was a real disconnect between companies and their customers.
              </p>
              <p>
                Building a Better Way - So, in 2024, I jumped into the Antler accelerator and founded version. Our mission is to make it easier for companies to connect with their customers on a personal level using hyper-personalized messaging.
              </p>
              <p>
                Think of us as your growth strategist that helps you send the right message at the right time, through the right channel, to maximize engagement and loyalty. <a href="https://versi0n.io" aria-label="link to trial page" style={{ textDecoration: "underline" }}>Try it out</a> to see the details.
              </p>
            </div>
          </div>
        </div>

        <BackToTop padding={tabletMode ? "10px" : "50px"} />
      </div>

      {tabletMode ? <MobileFooter /> : <Footer />}
    </HelmetProvider >
  )
}
